import React from 'react';
import {Link} from 'gatsby';

import Layout from '../components/Layout';
import dictionary from '../data/_dictionary';
import getLatinized from '../lib/getLatinized';

const allExceptLettersNumbersSpaces = /[^\w\s!?]/g;

const getPrepared = items => (
    items
        .filter((x, i, a) => i === a.findIndex(y => x.content === y.content))
        .sort((a, b) =>
            getLatinized(a.content.toLowerCase()).replace(allExceptLettersNumbersSpaces, '')
            >
            getLatinized(b.content.toLowerCase()).replace(allExceptLettersNumbersSpaces, '')
            ? 1 : -1
        )
        .reduce((acc, cur) => {
            if (
                !acc.length ||
                acc[acc.length - 1].incipit !== getLatinized(cur.content.toUpperCase())
                    .replace(allExceptLettersNumbersSpaces, '')[0]
                ) {
                acc.push({
                    incipit: getLatinized(cur.content.toUpperCase())
                        .replace(allExceptLettersNumbersSpaces, '')[0],
                    subitems: [cur],
                });
            } else {
                acc[acc.length - 1].subitems.push(cur);
            }
            return acc;
        }, [])
);

const Keywords = ({
    pageContext: {titleDictionaryIndex, items, description, from, keywords, lang, title, url},
}) => (
    <Layout
        description={description}
        keywords={keywords}
        lang={lang}
        name='keywords'
        path={from}
        title={title}
        url={url}
    >
        {titleDictionaryIndex && <h2>{dictionary[titleDictionaryIndex][lang]}</h2>}
        {getPrepared(items).map((item, i) => (
            <div key={i}>
                <h3>{item.incipit}</h3>
                <ul>
                    {item.subitems.map((subitem, subi) => (
                        <li key={subi}>
                            {subitem.linked ? (
                                <Link to={`/${lang}/search-by-keyword?query=${subitem.content}`}>
                                    {subitem.content}
                                </Link>
                            ) : (
                                subitem.content
                            )}
                        </li>
                    ))}
                </ul>
            </div>
        ))}
    </Layout>
);

export default Keywords;
